import { FaAddressBook, FaLinkedin, FaYoutube } from "react-icons/fa6";
import {
  BsFillRocketTakeoffFill,
  BsFillCalendarPlusFill,
  BsFillBookmarkStarFill,
} from "react-icons/bs";

import "./SocialLinksSection.css";

// eslint-disable-next-line react/prop-types
const SocialLinksSection = ({ socialDetails }) => {
  const SOCIAL_ICONS = {
    FaAddressBook: <FaAddressBook />,
    FaLinkedin: <FaLinkedin />,
    BsFillRocketTakeoffFill: <BsFillRocketTakeoffFill />,
    BsFillCalendarPlusFill: <BsFillCalendarPlusFill />,
    FaYoutube: <FaYoutube />,
    // default social
    BsFillBookmarkStarFill: <BsFillBookmarkStarFill />,
  };

  return (
    <div>
      <div className="social__links">
        <ul>
          {/* eslint-disable-next-line react/prop-types */}
          {socialDetails.map((details) => {
            return (
              !!details.source && (
                <li key={details.headingText} className={details.social}>
                  <div className="social-detail__block">
                    <span className="social__icons">
                      {
                        SOCIAL_ICONS[
                          details.iconText || "BsFillBookmarkStarFill"
                        ]
                      }
                    </span>
                    {details.downloadVcf ? (
                      <a download={details.downloadName} href={details.source}>
                        <span>
                          <h3 className="heading__text">
                            {details.headingText || "No Text available"}
                          </h3>
                          <p className="sub-heading__text">
                            {details.subHeadingText || "No Text available"}
                          </p>
                        </span>
                      </a>
                    ) : (
                      <a href={details.source || "#"}>
                        <span>
                          <h3 className="heading__text">
                            {details.headingText || "No Text available"}
                          </h3>
                          <p className="sub-heading__text">
                            {details.subHeadingText || "No Text available"}
                          </p>
                        </span>
                      </a>
                    )}
                  </div>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SocialLinksSection;
