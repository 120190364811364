import ProfileSection from "../ProfileSection/ProfileSection";
import SocialLinksSection from "../SocialLinksSection/SocialLinksSection";

// eslint-disable-next-line react/prop-types
const ProfileHOC = ({ contactDetails }) => {
  // eslint-disable-next-line react/prop-types
  let { headerDetail, mediaDetails, socialLinksDetails, name } = contactDetails;

  return (
    <div className="container">
      {/* Profile */}
      <ProfileSection introDetails={headerDetail} mediaDetails={mediaDetails} contactName={name} />
      {/* Social Links */}
      <SocialLinksSection socialDetails={socialLinksDetails} />
    </div>
  );
};

export default ProfileHOC;
