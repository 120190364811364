import ProfileHOC from "./components/ProfileHOC/ProfileHOC";
import HomeHOC from "./components/HomeHOC/HomeHOC";

import { contactDetails } from "./utlis/ContactDetails";
import { contactRouteDetails } from "./utlis/ContactRouteDetails";

import { Route, Routes } from "react-router-dom";

// import { useEffect, useState } from "react";
import "./App.css";

// ! Todo list
// * Responsive layout [DONE]
// * Favicon and Tab title to be changed [DONE]
// * Content changes
// * List of profiles to be added [DONE]

function App() {
  // ! delete the below logic used just for testing purposes
  // const [mvaDetailsResponse, setMvaDetailsResponse] = useState([]);
  // console.log(
  //   "🚀 ~ file: App.js:21 ~ App ~ mvaDetailsResponse:",
  //   mvaDetailsResponse
  // );

  // const apiCalls = () => {
  //   fetch("https://jsonplaceholder.typicode.com/todos/1")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       console.log(json);
  //       setMvaDetailsResponse((prevResponse) => [...prevResponse, json]);
  //     });
  // };

  // useEffect(() => {
  // [1, 2, 3].map((details) => {
  //   // & MVA API call
  //   apiCalls();
  //   return details;
  // });

  // apiCalls();
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeHOC />} />
        {contactRouteDetails.map((contact) => {
          return (
            <Route
              key={contact.route}
              path={contact.route}
              element={
                <ProfileHOC
                  contactDetails={contactDetails[contact.id]}
                  profileName={contact.profileName}
                />
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default App;

//* sample mapped component for reference purposes
//  <Route
//       path="/rishi"
//       element={
//         <ProfileHOC
//           contactDetails={contactDetails[0]}
//           profileName="Rishi"
//         />
//       }
//     />
