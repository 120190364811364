let contactDetails = [
  // Raunak
  {
    name: "Raunak",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919731595757",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919731595757",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919731595757",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:raunak@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Raunak.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/raunak-contact-card.vcf",
        downloadName: "raunak-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source:
          "https://calendly.com/raunak_dembla/introduction-to-decentro?month=2023-08",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/raunakdembla/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Rohit
  {
    name: "Rohit",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919971429764",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919971429764",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919971429764",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:rohit@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Rohit.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/rohit-contact-card.vcf",
        downloadName: "rohit-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/rohittaneja-decentro/30min?month=2023-08",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/rohit-taneja-69b30b13/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Pratik
  {
    name: "Pratik",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919969634150",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919969634150",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919969634150",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:pratik@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Pratik.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/pratik-contact-card.vcf",
        downloadName: "pratik-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/pratik-91/chat-with-pratik",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Gautam
  {
    name: "Gautam",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919004235816",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919004235816",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919004235816",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:gautam.mahesh@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Gautam.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/gautam-contact-card.vcf",
        downloadName: "gautam-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/gautam-decentro",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Drishti
  {
    name: "Drishti",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+917011502469",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+917011502469",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+917011502469",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:dristi.kalal@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Drishti.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/drishti-contact-card.vcf",
        downloadName: "drishti-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/drishti-decentro/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/dristi-kalal-45abb1a3/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Eeshan
  {
    name: "Eeshan",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919999409734",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919999409734",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919999409734",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:eeshan.sharrma@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Eeshan-1.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/eeshan-contact-card.vcf",
        downloadName: "eeshan-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/eeshan-sharrma/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Maulik
  {
    name: "Maulik",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919870388898",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919870388898",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919870388898",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:maulik.shah@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Maulik-web.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/maulik-contact-card.vcf",
        downloadName: "maulik-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/maulik-shah-decentro/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/maulik08/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Vaibhav
  {
    name: "Vaibhav",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919599224367",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919599224367",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919599224367",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:vaibhav.jain@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/vaibhav-Jain.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/vaibhav-contact-card.vcf",
        downloadName: "vaibhav-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/vaibhav-jain-1984/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/vaibhav-jain-59765515/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Avanindra
  // Avi
  {
    name: "Avanindra",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+918582994474",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+918582994474",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+918582994474",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:avanindra.jha@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Avanindra.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/avanindra-contact-card.vcf",
        downloadName: "avanindra-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/avi-decentro",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://in.linkedin.com/in/avanindra-jha",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Saksham
  {
    name: "Saksham",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+6586805076",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+6586805076",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+6586805076",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:saksham.shubham@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Saksham.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/saksham-contact-card.vcf",
        downloadName: "saksham-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/sakshamshubham/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Varun
  {
    name: "Varun",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+916307231919",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+916307231919",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+916307231919",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:varun.saxena@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Varun.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/varun-contact-card.vcf",
        downloadName: "varun-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/varun-saxena-1/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Keertana
  {
    name: "Keertana",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+919108757372",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+919108757372",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+919108757372",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:keertana.somanchi@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Keertana.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/keertana-contact-card.vcf",
        downloadName: "keertana-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/keertana-somanchi-6xe/30min",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },

  // Shrivardha
  {
    name: "Shrivardha",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+918792049205",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+918792049205",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+918792049205",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:shrivardha.patil@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Shrivardha.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/shrivardha-contact-card.vcf",
        downloadName: "shrivardha-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/shrivardha-patil",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/company/decentro/",
        subHeadingText: "Follow us on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Nitish
  {
    name: "Nitish",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:+918106534845",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=+918106534845",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:+918106534845",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:nitish.gulati@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://decentro-website-media-production.s3.ap-south-1.amazonaws.com/build/images/about-us/Nitish-Gulati.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/nitish-contact-card.vcf",
        downloadName: "nitish-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let’s Connect",
        source: "https://calendly.com/nitish-gulati-decentro",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/nitishgulati/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // Vishakha
  {
    name: "Vishakha",
    mediaDetails: [
      {
        iconText: "IoCall",
        sourceLink: "tel:8879610425",
        subText: "Call",
      },
      {
        iconText: "RiWhatsappFill",
        sourceLink: "whatsapp://send?phone=8879610425",
        subText: "WhatsApp",
      },
      {
        iconText: "MdMessage",
        sourceLink: "sms:8879610425",
        subText: "Message",
      },
      {
        iconText: "MdEmail",
        sourceLink: "mailto:vishakha.choudhary@decentro.tech",
        subText: "E-Mail",
      },
    ],
    headerDetail: {
      imageUrl:
        "https://d18fuqpnk61mcc.cloudfront.net/build/images/about-us/Vishakha.png",
    },
    socialLinksDetails: [
      {
        social: "social__container",
        headingText: "My Contact",
        source: "/vishakha-contact-card.vcf",
        downloadName: "vishakha-contact-card",
        downloadVcf: true,
        subHeadingText: "Save my details for quick access.",
        iconText: "FaAddressBook",
      },
      {
        social: "social__container",
        headingText: "Let's Connect",
        source: "https://calendly.com/vishakha-choudhary/support-call",
        subHeadingText: "Schedule a meeting here.",
        iconText: "BsFillCalendarPlusFill",
      },
      {
        social: "social__container",
        headingText: "Get Started",
        source: "https://decentro.tech/signup/",
        subHeadingText: "Sign Up Now!",
        iconText: "BsFillRocketTakeoffFill",
      },
      {
        social: "social__container",
        headingText: "LinkedIn",
        source: "https://www.linkedin.com/in/vishakha-choudhary-592b81a5/",
        subHeadingText: "Follow me on LinkedIn.",
        iconText: "FaLinkedin",
      },
      {
        social: "social__container",
        headingText: "YouTube",
        source: "https://www.youtube.com/channel/UCpgo1uTF8sByehHfjIqDhYA",
        subHeadingText: "Follow us on YouTube.",
        iconText: "FaYoutube",
      },
    ],
  },
  // ----------*----------*----------*----------*----------*----------*
  // ----------*----------*----------NEOWISE----------*----------*----------*
  // ----------*----------*----------*----------*----------*----------*
];

export { contactDetails };
