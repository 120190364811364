import "./HomeHOC.css";

// eslint-disable-next-line react/prop-types
const HomeHOC = () => {
  return (
    <div className="container">
      <div className="home__container">
        <a
          href="https://decentro.tech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bg-img"
            style={{ color: "white" }}
            src="https://d18fuqpnk61mcc.cloudfront.net/build/images/banner.svg"
            alt="decentro logo"
          />
        </a>
      </div>
    </div>
  );
};

export default HomeHOC;
